import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import HeroEnd from "../../components/hero-end";

const PhotographersFilmAndVideoMakersPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "16.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				daniel: file(
					relativePath: { eq: "testimonials/daniel-brookbank.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				elizabethK: file(
					relativePath: { eq: "testimonials/elizabeth-keates.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				sarah: file(relativePath: { eq: "testimonials/sarah-bennett.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				moxy: file(relativePath: { eq: "testimonials/Moxy-Child.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andrew: file(relativePath: { eq: "testimonials/selda-koydemir.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const moxy = data.moxy.childImageSharp.gatsbyImageData;
	const elizabethK = data.elizabethK.childImageSharp.gatsbyImageData;
	const sarah = data.sarah.childImageSharp.gatsbyImageData;
	const schemaImage = data.heroImg.publicURL;
	const title =
		"Photographers, Film & Video Makers | Business & Executive Coaching in London";
	const description =
		"Photographers, film, and video makers excel in capturing and crafting compelling visual stories, but often face challenges when looking to grow their creative businesses profitably.";

	const heroImage = data.heroImg.childImageSharp.fluid;
	const daniel = data.daniel.childImageSharp.gatsbyImageData;
	const andrew = data.andrew.childImageSharp.gatsbyImageData;

	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Industries",
				item: {
					url: `${siteUrl}/industries`,
					id: `${siteUrl}/industries`,
				},
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Photographers, Film & Video Makers",
				item: {
					url: `${siteUrl}/industries/photographers-film-and-video-makers`,
					id: `${siteUrl}/industries/legal-professionals`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/industries/photographers-film-and-video-makers`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Photographers, film & video makers business & executive coaching in London",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title="Business & Executive Coach for Photographers, film & video makers"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							{/* <p>
                As a professional in a leadership position, you’re constantly
                juggling the priorities of your job with everything else in
                life. You’re likely responsible for strategic thinking,
                overseeing operations, developing new approaches, and much more.
              </p> */}
							<p>
								Photographers, film, and video makers excel in capturing and
								crafting compelling visual stories, but often face challenges
								when looking to grow their creative businesses profitably. As
								the media landscape continues to shift and evolve, it becomes
								essential not just to work 'in' your business but also 'on' it.
							</p>
							<p>
								A business and executive coach serves as a crucial ally in your
								professional journey, providing the guidance needed to optimise
								your business model and implement effective strategies for
								growth. They help you focus your efforts on the aspects of your
								business that drive profitability and sustainable growth,
								ensuring you meet the current market demands and stay ahead of
								emerging trends.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Tailored Coaching for Photographers and Videographers
							</h3>
							<p>
								As a photographer, filmmaker, or video maker, finding your own
								way through the competitive and ever-evolving landscape of
								visual media can be challenging, especially when trying to grow
								or scale your business profitably. In our coaching sessions,
								we'll focus on strategies like honing your unique style,
								expanding into new market segments, and pricing strategy to
								boost profitability.
							</p>
							<p>
								With over four decades of business experience, including more
								than seven years specialising in coaching, I can provide you
								with knowledge in helping creatives like you overcome the unique
								challenges faced in the visual arts industry. Photographers and
								video makers must continuously adapt to new technologies,
								shifting consumer preferences, and the changing demands of the
								media landscape while trying to sustain and grow their business.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								How Business Coaching can elevate your business
							</h3>

							<p>
								As a photographer, filmmaker, or video maker, you have the
								ability to capture and create compelling visual content. Yet,
								when it comes to scaling your own business and ensuring it
								thrives profitably, you might find yourself at a crossroads.
								This is where a business coach can prove invaluable guiding you
								in refining and expanding on your business model.{" "}
							</p>
							<p>Imagine yourself:</p>
							<ul className="pl-4">
								<li>
									Developing a niche marketing strategy that attracts and
									retains the ideal clientele who appreciate and are willing to
									pay for high-quality visual art.
								</li>
								<li>
									Introducing new services or products that complement your
									existing portfolio, such as drone photography, virtual tours,
									or video production for social media campaigns.
								</li>
								<li>
									Adjusting your pricing to match the quality and uniqueness of
									your work, ensuring you are compensated fairly for your
									creativity and effort.
								</li>
								<li>
									Growing your team strategically to support larger projects and
									expanded operations without compromising the quality of your
									work.
								</li>
							</ul>
							<p>
								Partnering with a business coach who understands the specific
								needs and challenges of the visual content industry can help you
								identify these hurdles, navigate through them, and chart a clear
								path towards your business aspirations.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">So, what’s been stopping you?</h2>
							<p>
								Are you feeling overwhelmed by the pressure of constant
								deadlines and project demands, with little room left to work in
								the business rather than on it? If you're finding it challenging
								to expand your services, tap into new markets, or refine your
								pricing models to boost profitability, you're certainly not
								alone.
							</p>
							<p>
								Here’s how a specialised Business & Executive Coach can assist:
							</p>
							<ul className="pl-4">
								<li>
									Strategic Expansion: Receive expert guidance on expanding your
									portfolio and exploring untapped markets.
								</li>
								<li>
									Pricing Strategies: Get help in evaluating and adjusting your
									pricing to maximise profitability without compromising
									quality.
								</li>
								<li>
									Product Innovation: Gain support in enhancing your current
									offerings or introducing innovative services to keep you ahead
									of competitors.
								</li>
								<li>
									Team Building: Learn strategies to recruit, develop, and
									motivate a team that not only meets your professional needs
									but also supports your creative vision.
								</li>
							</ul>
							<p>
								Now is the ideal time to address these challenges with the
								support of an experienced, impartial, and confidential business
								coach. Together, we can turn these obstacles into opportunities
								for professional growth and business success.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Empowering your business with professional coaching
							</h2>
							<p>
								As a business and executive coach with years of experience
								coaching business owners in the visual arts sector, I understand
								the unique challenges faced by photographers, film, and video
								makers in today's competitive market.{" "}
							</p>
							<p>
								With me as your business coach, you will benefit from
								personalised coaching sessions designed to meet your specific
								needs. We will focus on several pivotal areas:
							</p>
							<ul className="pl-4">
								<li>
									Personal and Professional Development: Enhancing your
									technical skills and business knowledge to keep you at the
									forefront of industry advancements.
								</li>
								<li>
									Leadership and Creative Team Management: Developing and
									managing a team that's as creative and forward-thinking as the
									projects you undertake.
								</li>
								<li>
									Strategic Business Planning: Creating customised strategies
									that reflect your unique artistic vision and market
									positioning.
								</li>
								<li>
									Innovation in Products and Services: Evolving your offerings
									to meet the changing demands of your audience and maximising
									market opportunities.
								</li>
							</ul>
							<p>
								My coaching approach offers tailored guidance and a confidential
								space to explore new possibilities and address challenges
								head-on.{" "}
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/12.jpg"
							/>
							<h2 className="text-primary pb-3">
								About me as a business coach
							</h2>

							<p>
								With years of experience in business management, an MBA, and a
								background in counselling, I'm well-equipped to understand the
								unique challenges faced by photographers, filmmakers, and video
								producers. It's not just about capturing stunning visuals or
								crafting compelling narratives; it's about ensuring that your
								creative business is profitable and sustainable too.
							</p>
							<p>
								In our coaching sessions, we will create a safe, confidential
								environment where you can freely discuss everything from
								defining your artistic vision to implementing effective business
								strategies. We'll work on refining your pricing models,
								expanding your service offerings, and targeting your marketing
								efforts to reach the right audiences.{" "}
							</p>
							<p>
								Are you ready to elevate your creative career? Let’s connect and
								start this transformational journey.
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">About me as a business coach</h2>
							<p>
								With years of experience in business management, an MBA, and a
								background in counselling, I'm well-equipped to understand the
								unique challenges faced by photographers, filmmakers, and video
								producers. It's not just about capturing stunning visuals or
								crafting compelling narratives; it's about ensuring that your
								creative business is profitable and sustainable too.
							</p>
							<p>
								In our coaching sessions, we will create a safe, confidential
								environment where you can freely discuss everything from
								defining your artistic vision to implementing effective business
								strategies. We'll work on refining your pricing models,
								expanding your service offerings, and targeting your marketing
								efforts to reach the right audiences.{" "}
							</p>
							<p>
								Are you ready to elevate your creative career? Let’s connect and
								start this transformational journey.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Reviews from my business and executive coaching clients
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						name="Elizabeth Keates Film & Photography"
						review="Astonishingly brilliant! Two rather powerful words to describe how I felt about Jason Cornes after our meeting. To cut to the chase, which is exactly what Jason did with a business growth issue I had been stuck with for quite a while, I found the session inspirational and I finally have a road map forward. Through hard-hitting truths Jason was able to show me exactly where I was stuck and why. He guided me towards a clear plan of action and together we identified the areas where I feel I need support to enable me to achieve the goal I have been trying to reach for a number of years now. Moments felt productively uncomfortable as we addressed my comfort zone and looked at my self-limiting and destructive cycles, but before my heart rate became too high, Jason quickly showed me ways to reach a solution and above all he has laid a plan to give me the support I need to let go and actually move forward. With Jason coaching from the side-line, I truly can’t wait to report back on where my business will be in 12 months."
						linkedIn="https://www.linkedin.com/in/elizabeth-keates-film-photography"
						profilePic={elizabethK}
					/>
					<HorizontalTestimonial
						name="Sarah Bennett Commercial Photography."
						review="I’ve worked with Jason for two years this month. When I came to Jason for help, I wasn’t entirely sure what a business coach did, I though maybe a few sessions then that was it..2 years on and I can truly say my business has gone from strength to strength, but I still need him. Jason is the person I tell all my ideas to, and bounce them off. He’s the one person that will give me an honest answer and opinion, he tells me what I need to hear, not necessarily what I want to hear. Because he’s always run his own businesses, he knows his stuff, its based on training yes, but more importantly I fell on his 30 years of experience in running his own companies. His ideas are inspiring and I can’t recommend him enough to others."
						linkedIn="https://www.linkedin.com/in/sarahbennettcommercial/"
						profilePic={sarah}
					/>
					<HorizontalTestimonial
						name="Moxy Child. Moxy Commercial Photography."
						review="Jason is a coach who actually LISTENS! – I would highly recommend his services to anyone in business. Jason gave me the courage to scrap my old business identity and to start afresh. He supported me through the whole business of putting things into place properly rather than haphazardly as was done before."
						linkedIn="https://www.linkedin.com/in/moxychild/"
						profilePic={moxy}
					/>
				</Container>
			</section>
			{/* <section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Break the cycle with executive coaching in London
							</h3>
							<p>
								In this 24/7 world, leaders, senior managers, owners and
								executives are so busy that they don’t have the time to devote
								to their own development or to the development of the people who
								work for them. In other words, they’re caught in a vicious cycle
								of always “being busy.”
							</p>
							<p>
								In the same way, some struggle and worry about not being able to
								think clearly. You may have an idea, but you can’t seem to get a
								clear picture on what to do next, or perhaps you need to make
								decisions, but you don’t feel confident that the decision you
								make will be the right one.
							</p>
							<p>
								This is precisely why you need executive coaching services. As
								an executive coach in London, I can help you break the cycle of
								always being busy while giving you the confidence to make
								decisions that positively impact your life.
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Ready to work towards that positive future?
							</h2>
							<p>
								Unfortunately, though, none of us have a crystal ball. I can’t
								tell you what life will be like if you do, take that job, wake
								up filled with confidence, or grow your business tenfold - but
								what I can tell you is...
							</p>
							<p>If you do nothing, nothing will change.</p>
							<p>
								On the other hand, it’s amazing what we can achieve together
								when we use coaching to focus on what you really want.
							</p>
							<p>
								So, with nothing to lose - why not book a introductory call with
								me?
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Let's schedule a discovery call together</h3>
							<p>
								Your attention to detail and artistic flair bring projects to
								life in ways only you can. However, the creative passion that
								drives you can sometimes make it challenging to focus on growing
								your business.
							</p>
							<p>
								Why not schedule a discovery call with me? This relaxed
								conversation will be an opportunity for us to get to know each
								other and discuss how personalised business and executive
								coaching could help you not only meet but surpass your
								professional goals.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default PhotographersFilmAndVideoMakersPage;
